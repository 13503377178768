import React from 'react';
import { connect } from 'react-redux';
import { BasePageStyles } from '../../../hooks/styles'
import { Button, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Popper, SwipeableDrawer, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import { OrderActions } from '../../../redux/actions';
import config from '../../../config';
import { useMediaQuery } from 'react-responsive';
import MenuIcon from '@mui/icons-material/Menu';
import MediaQuery from 'react-responsive';

interface MenuListProps {}

const MenuListComposition = (props: MenuListProps) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 900px)'
  })
  const classes = BasePageStyles();
  const [openDrawer, setOpenOpenDrawer] = React.useState(false);
  const [onProductPage, setOnProductPage] = React.useState(false);
  const [onTrackingPage, setOnTrackingPage] = React.useState(false);
	const history = useHistory(); 

	const routeChange = (path: string) =>{ 
		history.push(path);
	}

  React.useEffect(() => {
		// Fake onHover on links when user is on the referenced page
    switch(window.location.pathname){
      case '/':
        setOnProductPage(true)
        setOnTrackingPage(false)
        break
      case '/order':
        setOnProductPage(false)
        setOnTrackingPage(true)
        break
      default:
        setOnProductPage(false)
        setOnTrackingPage(false)
        break
    }
	}, []);

  return (
    <div>
      <MediaQuery minWidth={1150}>
        <div>
          <Button
            onClick={() => window.location.href = `${config.wordpressUrl}/product-details`}
            className={classes.profileMenuBtn}
          >
            <h3>Product Details</h3>
          </Button>
          <Button
            onClick={() => window.location.href = `${config.wordpressUrl}/resources`}
            className={classes.profileMenuBtn}
          >
            <h3>Resources</h3>
          </Button>
          <Button
            onClick={() => window.location.href = `${config.wordpressUrl}/about`}
            className={classes.profileMenuBtn}
          >
            <h3>About Us</h3>
          </Button>
          <Button
            onClick={() => routeChange('/')}
            className={onProductPage ? classes.fakeOnHoverNav : classes.profileMenuBtn}
          >
            <h3>Order Now</h3>
          </Button>
          <Button
            onClick={() => routeChange('/order')}
            className={onTrackingPage ? classes.fakeOnHoverNav : classes.profileMenuBtn}
          >
            <h3>Track Order</h3>
          </Button>
        </div>
        </MediaQuery>
        <MediaQuery maxWidth={1149}>
        <div>
          <MenuIcon onClick={() => setOpenOpenDrawer(!openDrawer)} fontSize='large'/>
          <SwipeableDrawer
            anchor={'top'}
            open={openDrawer}
            onClose={() => setOpenOpenDrawer(false)}
            onOpen={() => setOpenOpenDrawer(true)}
          >
            <MenuList>
              <MenuItem>
                <Button
                  onClick={() => window.location.href = `${config.wordpressUrl}/product-details`}
                  className={classes.profileMenuBtn}
                >
                  <h3>Product Details</h3>
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={() => window.location.href = `${config.wordpressUrl}/resources`}
                  className={classes.profileMenuBtn}
                >
                  <h3>Resources</h3>
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={() => window.location.href = `${config.wordpressUrl}/about`}
                  className={classes.profileMenuBtn}
                >
                  <h3>About Us</h3>
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={() => routeChange('/')}
                  className={classes.profileMenuBtn}
                >
                  <h3>Order Now</h3>
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={() => routeChange('/order')}
                  className={classes.profileMenuBtn}
                >
                  <h3>Track Order</h3>
                </Button>
              </MenuItem>
            </MenuList>
          </SwipeableDrawer>
        </div>
        </MediaQuery>
    </div>
  );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MenuListComposition);


