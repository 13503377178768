import { Theme, adaptV4Theme, createTheme } from '@mui/material';

declare module '@mui/system' {
  interface DefaultTheme extends Theme {}
}

export const themeNames = {
  LIGHT: 'light',
  DARK: 'dark'
}

export const globalColors = {
  NARCAN_GRAY: '#2D2D2D',
  NARCAN_DARK_BLUE: '#053155',
  NARCAN_BLUE: '#04B9FB',
  NARCAN_BRIGHT_BLUE: '#04D1FB',
  ODR_ORANGE: '#ED1C24', 
  ODR_DARK_ORANGE: '#A50606', 
  WHITE: '#fafafa', 
  BLACK: '#000000', 
  FFF_DARK_BLUE: '#0064A1', 
  EMPHASIS_PURPLE: '#3f51b5',
  TABLE_WARNING: '#ff9999',
  DARK_GRAY: '#303030',
  MEDIUM_GRAY: '#424242',
  LIGHT_GRAY: '#616161',
  ERROR_RED: '#f44336'
}

const breakpointVals = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

/***** component overrides applicable to all themes *****/
const globalOverrides = {
  MUIDataTable: {
    responsiveScroll: { 
      height: '600px' 
    }
  },
  MUIDataTableToolbar: {
    root: {
      paddingLeft: 16,
      minHeight: 'unset'
    }
  },
  MUIDataTableBodyCell: { 
    root: {
      fontSize: 12,
      backgroundColor: "transparent !important", 
      color: "inherit"
    }
  },
  MUIDataTableHeadCell: {
    root: {
      fontSize: 12
    },
    hintIconAlone: {
      fontSize: '1.2em !important',
      marginTop: '3px !important',
      color: '#757575'
    }
  },
  MUIDataTableSelectCell: {
    checked: {
      color: `${globalColors.NARCAN_GRAY} !important`
    },
  },
  MUIDataTableToolbarSelect: {
    root: {
      justifyContent: 'flex-start'
    }
  },
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: globalColors.NARCAN_GRAY,
    },
  },
  MuiDrawer: {
    paper: {marginTop: 'max(10vh, 8%)', paddingTop: 0, position: 'relative'}
  },
  MuiMenuItem: {
    root: {
      justifyContent: 'space-evenly', alignItems: 'center'
    }
  }
};

const cssBaselineGlobals = {
  '.roleCheckboxWrapper .MuiFormControlLabel-label': {
    fontSize: '11px !important'
  },
  '.MuiTableHead-root .MuiButton-root, .groupByLink .MuiButton-root, .MuiCardHeader-root': {
    backgroundColor: 'inherit !important'
  },
  '.MuiCollapse-root .MuiPaper-root': {
    width: '100%'
  },
  '.MuiAccordionSummary-root': {
    minHeight: 60
  },
  'a': {
    color: 'inherit !important'
  },
  '.MuiOutlinedInput-root .MuiInputBase-input .MuiOutlinedInput-input': {
    padding: '17px 14px',
    backgroundColor: 'white',
    color: `${globalColors.NARCAN_GRAY} !important`
  },
  '.MuiDrawer-paper': {
    position: 'relative !important', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'
  }
}

const buttonGlobals = {
  root: {
    color: '#fff',
    backgroundColor: globalColors.ODR_ORANGE,
    textTransform: 'none',
    font: 'normal normal 600 15px/15px Poppins, Sans-serif',
    '&:hover': {
      backgroundColor: globalColors.ODR_DARK_ORANGE,
      '&.lightButton': {
        backgroundColor: globalColors.ODR_ORANGE
      },
      '&.cancelButton': {
        backgroundColor: globalColors.NARCAN_GRAY,
        color: '#fff'
      }
    },
    '&:disabled': {
      backgroundColor: globalColors.LIGHT_GRAY,
      color: '#fff',
    },
    '&.lightButton': {
      backgroundColor: globalColors.ODR_ORANGE,
    },
    '&.cancelButton': {
      backgroundColor: globalColors.WHITE,
      borderColor: globalColors.NARCAN_GRAY,
      color: globalColors.NARCAN_GRAY,
    }
  },
  contained: {
    backgroundColor: globalColors.ODR_ORANGE,
    '&.MuiButton-containedPrimary': {
      backgroundColor: globalColors.ODR_ORANGE,
      '&:hover': {
        backgroundColor: globalColors.ODR_DARK_ORANGE,
        '&.contactButton': {
          backgroundColor: globalColors.NARCAN_BRIGHT_BLUE,
        }
      },
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: globalColors.ODR_ORANGE,
      '&:hover': {
        backgroundColor: globalColors.NARCAN_GRAY
      },
      '&.contactButton': {
        backgroundColor: globalColors.NARCAN_BLUE,
      }
    },
    '&:hover': {
      backgroundColor: globalColors.ODR_DARK_ORANGE
    },
    '&:disabled': {
      backgroundColor: globalColors.LIGHT_GRAY
    },
    '&.contactButton': {
      backgroundColor: globalColors.NARCAN_BLUE,
    }
  }
};

const themeDefinitions: any = {
  'light': {
    breakpoints: {
      values: {
        ...breakpointVals
      },
    },
    palette: {
      type: "light",
      primary: {
        main: globalColors.NARCAN_GRAY
      },
      secondary: {
        main: globalColors.NARCAN_GRAY,
        contrastText: '#fff'
      },
      text: {
        primary: globalColors.NARCAN_GRAY,
        secondary: globalColors.NARCAN_GRAY
      },
      background: {
        paper: '#fff',
        default: '#fafafa'
      }
    },
    overrides: {
      ...globalOverrides,
      MuiCssBaseline: {
        '@global': {
         ...cssBaselineGlobals,
         '.MuiTabs-indicator': {            
            backgroundColor: `${globalColors.FFF_DARK_BLUE} !important`
          },
          // '.MuiTableHead-root .MuiButton-label, .groupByLink .MuiButton-label': {
          //   color: 'rgba(0, 0, 0, 0.87) !important'
          // } 
        }
      },
      MuiAppBar: {
        root: {
          backgroundColor: `${globalColors.WHITE} !important`,
          color: `${globalColors.NARCAN_GRAY} !important`
        }
      },
      MuiContainer: {
        root: {
          backgroundColor: '#fafafa',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(odd)': { 
            backgroundColor: '#eff4fb'
          },
          '&.mui-row-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
          }
        }
      },
      MuiList: {
        padding: {
          paddingTop: '5px',
          paddingBottom: '4px'
        }
      },
      MuiAvatar: {
        root: {
          border: '3px solid rgba(0, 0, 0, 0.54)',
          width: 50,
          height: 50
        },
        colorDefault: {
          color: 'rgba(0, 0, 0, 0.87)',
          backgroundColor: '#fff',
        }
      },
      MuiTabs: {
        root: {
          backgroundColor: globalColors.NARCAN_GRAY,
        }
      },
      MuiTab: {
        wrapper: {
          color: '#fff'
        } 
      },
      MuiButton: {
         ...buttonGlobals,
        label: {
          color: "#fff",
          '&.lightButton': {
            color: 'rgba(0, 0, 0, 0.87)'
          }
        }
      },
      MuiSvgIcon:{
        root: {
          color: globalColors.NARCAN_GRAY
        }
      }
    }
  },

  'dark': {
    breakpoints: {
      values: {
        ...breakpointVals
      },
    },
    palette: {
      type: "dark",
      primary: {
        main: globalColors.NARCAN_GRAY,
      },
      secondary: {
        main: globalColors.NARCAN_GRAY,
        contrastText: '#fff'
      },
      text: {
        primary: '#fff',
        secondary: '#fff',

      },
      background: {
        paper: globalColors.FFF_DARK_BLUE,
        default: globalColors.DARK_GRAY
      }
    },
    overrides: {
      ...globalOverrides,
      MuiCssBaseline: {
        '@global': {
          ...cssBaselineGlobals,
          body: {
            backgroundColor: globalColors.DARK_GRAY,
          },
          '.MuiInput-underline:after': {
            borderColor: `${globalColors.NARCAN_GRAY} !important`
          }
        }
      },
      MuiContainer: {
        root: {
          backgroundColor: globalColors.DARK_GRAY,
        }
      },
      MuiFormLabel: {
        root: {
          '&.Mui-focused': {
            color: globalColors.NARCAN_GRAY
          }
        },
        wrapper: {
          color: globalColors.NARCAN_GRAY
        }
      },
      MuiGrid: {
        root: {
          color: '#fff'
        }
      },
      MuiAppBar: {
        root: {
          backgroundColor: `${globalColors.FFF_DARK_BLUE} !important`
        },
        primary: {
          main: globalColors.FFF_DARK_BLUE
        }
      },
      MuiAvatar: {
        root: {
          border: '3px solid #fff',
          width: 50,
          height: 50
        },
        colorDefault: {
          color: '#fff',
          backgroundColor: globalColors.FFF_DARK_BLUE,
        }
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(odd)': { 
            backgroundColor: globalColors.MEDIUM_GRAY
          },
          '&:nth-child(even)': { 
            backgroundColor: globalColors.DARK_GRAY
          },
          '&.mui-row-selected': {
            backgroundColor: `${globalColors.FFF_DARK_BLUE} !important`
          }
        }
      },
      MuiPrivateTabIndicator: {
        root: {
          backgroundColor: '#fff'
        }
      },
      MuiTab: {
        wrapper: {
          color: '#fff'
        }
      },
      MuiListItem: {
        root: {
          color: '#fff'
        }
      },
      MuiButton: {
        ...buttonGlobals,
        label: {
          color: "#fff",
          '&.lightButton': {
            color: '#fff'
          }
        }
      }
    }
  }  
}

export const getMuiTheme = (themeVersion: string=themeNames.LIGHT) => {
  if (themeDefinitions[themeVersion]) {
    return createTheme(adaptV4Theme(themeDefinitions[themeVersion])) as any;
  }
  else {
    console.error('Could not find theme definition:', themeVersion);
    return createTheme() as any;
  }
}


export default getMuiTheme;




