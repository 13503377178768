import React from 'react';
import { connect } from 'react-redux';
import { BasePageStyles } from '../../hooks/styles';
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';

interface ValidatedAddress {
	street: string, 
    city: string, 
    state: string, 
    zip: string,
    country: string
}

interface ValidateAddressModalProps {
	validatedAddresses: any[]
	closeModal: () => void
	onSelect: (street: string, city: string, state: string, zip: string) => void
}

const ValidateAddressModal: React.FC<ValidateAddressModalProps> = props => {
	const classes = BasePageStyles();
	const [selectedAddressIndex, setSelectedAddressIndex] = React.useState<(number)>(-1)

	const handleCancel = () => {
		props.closeModal();
	};

	const handleSelect = () => {
        if(selectedAddressIndex >= 0) {
            const selectedAddress = props.validatedAddresses[selectedAddressIndex];
            props.onSelect(selectedAddress.street, selectedAddress.city, selectedAddress.state, selectedAddress.zip);
            props.closeModal();
        }
	};

	return (
        <Grid container className={`${classes.baseModal} ${classes.validateAddressModal}`}>
            <Grid item xs={12}>
                <h2>Validate Address</h2>
            </Grid>
            <Grid item xs={12}>
                <h4>Please select one of the validated addresses listed below.</h4>
            </Grid>
            <Grid item xs={12}>
                <RadioGroup
                    aria-labelledby="demo-error-radios"
                    name="quiz"
                    value={selectedAddressIndex}
                    onChange={(event: React.ChangeEvent, value: string) => {setSelectedAddressIndex(parseInt(value))}}
                >
                    {props.validatedAddresses.map((address: ValidatedAddress, index: number) => {
                        return (<FormControlLabel value={index} control={<Radio /> } label={`${address.street} ${address.city}, ${address.state} ${address.zip}`} key={Math.random()}/>)
                    })}
                </RadioGroup>
            </Grid>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '50px'}}>
                <Button className={'cancelButton'}
                    variant="outlined"
                    onClick={handleCancel}
                >
                    CANCEL
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSelect}
                >
                    SELECT
                </Button>
            </Grid>
        </Grid>
	);
};

const mapStateToProps = (state: any) => ({
    validatedAddresses: state.order.validatedAddresses || []
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateAddressModal);
