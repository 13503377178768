import dev from './dev.json';
import qa from './qa.json';
import prod from './prod.json'

const configFiles: any = {
  'dev': dev,
  'qa': qa,
  'app': prod
}

function getConfig() {
  // @ts-ignore
  const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

  
  if (!subdomain) {
    return dev;
  }
  

  return configFiles[subdomain];
}

export default (getConfig());
