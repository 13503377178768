import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router'
import { History } from 'history';

import * as uiReducer from './ui.reducer';
import * as orderReducer from './order.reducer';
import * as captchaReducer from './captcha.reducer';


const createRootReducer = (history: History<any>) => combineReducers(Object.assign(
  { router: connectRouter(history) },
  uiReducer,
  orderReducer,
  captchaReducer,
));
export default createRootReducer;
