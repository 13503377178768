import axios from 'axios';
import { HttpResponse } from '../models/common/result.set';
import { ORDER } from '../constants/api.url';
import { GetRecaptchaToken } from '../../hooks/functions/GetRecaptchaToken';

var FileSaver = require('file-saver');
export default class Api {

  static getBaseURL() {
    return '';
  }

  static async headers(route: string) {
    const invisibleRecaptchaRoutes = [ORDER.ORDERS, ORDER.GET_TAX, ORDER.VALIDATE_ADDRESS]
    const recapchaProtectedRoutes = [...invisibleRecaptchaRoutes, ORDER.GET_CC_PAYMENT_TOKEN]
    const recaptchaType = invisibleRecaptchaRoutes.includes(route) ? 'invisible' : 'visible'
    console.log(route,recapchaProtectedRoutes.includes(route))
    let header: any = {
      'Content-Type': 'application/json',
      // "Access-Control-Allow-Headers": "content-type, origin, accept, x-recaptcha-token",
			...(recapchaProtectedRoutes.includes(route) && { Authorization: `${recaptchaType} ${await GetRecaptchaToken(invisibleRecaptchaRoutes.includes(route))}` }),
			// ...(recapchaProtectedRoutes.includes(route) && { 'x-recaptcha-type': invisibleRecaptchaRoutes.includes(route) ? 'invisible' : 'visible' })
    };
    
    return header;
  }

  static get(route: string, params: any = null): Promise<HttpResponse<any>> {
    return this.xhr(route, params, 'GET');
  }

  static post(route: string, params: any): Promise<HttpResponse<any>> {
    return this.xhr(route, params, 'POST');
  }

  static put(route: string, params: any): Promise<HttpResponse<any>> {
    return this.xhr(route, params, 'PUT');
  }

  static patch(route: string, params: any): Promise<HttpResponse<any>> {
    return this.xhr(route, params, 'PATCH');
  }

  static delete(route: string, params: any): Promise<HttpResponse<any>> {
    return this.xhr(route, params, 'DELETE');
  }

  static encodeUrlParams(url: string, params: { key: string, value: string }[]) {
    let paramString = '';
    params.forEach((param, index) => {
      paramString += (index > 0) ? '&' + param.key + '=' + encodeURI(param.value) : '?' + param.key + '=' + encodeURI(param.value)
    })
    url += paramString;
    return url;
  }

  static async xhr(route: string, params: any, verb: any): Promise<HttpResponse<any>> {

    let url = Api.getBaseURL() + route;
    if (verb === 'GET' && params) {
      url = this.encodeUrlParams(url, params);
      params = null;
    }
    const headers = await Api.headers(route);
    let options: any;

    if (params && params.option && params.option.content_type) {
      options = Object.assign({ method: verb, headers: headers, url: url, responseType: 'arraybuffer' as 'arraybuffer' }, params ? { data: params } : null);
    } 
    // else if (params && params.option && params.option.content_type) {
    //   options = Object.assign({ method: verb, headers: headers, url: url, responseType: 'arraybuffer' as 'arraybuffer' }, params ? { data: params } : null);
    // } 
    else {
      options = Object.assign({ method: verb, headers: headers, url: url }, params ? { data: params } : null);
    }

    return axios(options).then(resp => {
      const header = resp.headers;
      if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
        if (header['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml') {
          const blob = new Blob([resp.data], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          FileSaver.saveAs(blob, params.option.fileName);
          throw new Error('File Saved');
        } else {
          return resp;
        }
      } else {
        throw resp;
      }
    }).catch(error => {
      throw error;
    });
  }
}