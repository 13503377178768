import { connect } from 'react-redux';
import { ProductComponent } from './product.component';
import { OrderActions } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
  odrQuantity: state.order.odrQuantity,
  narcanQuantity: state.order.narcanQuantity,
	narcanChecked: state.order.narcanChecked ?? false,
	autoCalcNarcanChecked: state.order.autoCalcNarcanChecked ?? true,
  products: state.order.products || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  setOdrQuantity: (quantity: number) => dispatch(OrderActions.setOdrQuantity(quantity)),
  setNarcanQuantity: (quantity: number) => dispatch(OrderActions.setNarcanQuantity(quantity)),
	setNarcanChecked: (isChecked: boolean) => dispatch(OrderActions.setNarcanChecked(isChecked)),
	setAutoCalcNarcanChecked: (isChecked: boolean) => dispatch(OrderActions.setAutoCalcNarcanChecked(isChecked)),
});

export const ProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductComponent);