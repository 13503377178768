import React from 'react';
import { connect } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { CssBaseline, AppBar, Toolbar, Button, Box, Divider, Grid } from '@mui/material/';
import MenuListComposition from './menuList';
import { IMAGES_URL } from '../../../constants/Images';
import { BasePageStyles } from '../../../hooks/styles'
import Loading from '../../../components/loading';
import { useHistory } from "react-router-dom";
import { UIActions } from '../../../redux/actions';
import config from '../../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const recaptchaRef = React.createRef<ReCAPTCHA>()

const BasePage = (props: any) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 900px)'
  })
  const classes = BasePageStyles();
  const [urlIsProcessed, setUrlIsProcessed] = React.useState(false);
  const history = useHistory(); 

	const routeChange = (path: string) =>{ 
		history.push(path);
	}

  React.useEffect(() => {
    if (!urlIsProcessed) {
      props.setUrlFilter(window.location.search)
      setUrlIsProcessed(true)
    }
  }, [urlIsProcessed])

  React.useEffect(() => {
    if (!props.dataIsPreloaded) {
      props.preloadData()
    }
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={clsx(classes.root, (props.isLoading ? "show-loader" : ""))}>
      <CssBaseline />

      <AppBar elevation={10} className={clsx(classes.appBar)}>
        <div className={classes.toolbarMargin} />
        <Toolbar className={classes.toolbar}>
          <img src={IMAGES_URL.ODR_LOGO_BLACK} alt="logo" className={classes.odRescueLogo} style={{cursor: 'pointer'}} onClick={() => window.location.href = `${config.wordpressUrl}`}/>
          <MenuListComposition />
        </Toolbar>
      </AppBar>

      {urlIsProcessed && 
        <main className={classes.content}>
          {props.dataIsPreloaded ? props.children : <Loading message="Loading..." />}
        </main>
      }

      {props.isLoading ? <Loading message="Loading..." /> : ''}

      <AppBar elevation={0} className={clsx(isDesktopOrLaptop ? classes.appBarBottom : classes.appBarBottomMobile)}>
        <Toolbar className={isDesktopOrLaptop ? classes.footer : classes.footerMobile}>
          <Grid container columns={{xs: 12, md: 12}} sx={{justifyContent: 'center', padding: '80px'}}>
            <Grid container item xs={12} md={5} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: isDesktopOrLaptop ? 'space-around' : 'center', textAlign: isDesktopOrLaptop ? 'left' : 'center'}}>
                  <img src={IMAGES_URL.FFF_LOGO_FOOTER_BOAT} alt="logo" className={isDesktopOrLaptop ? classes.footerLogo : classes.footerLogoMobile} />
                  <p className={classes.footerLegal} style={isDesktopOrLaptop ? {} : {textAlign: 'center'}}><strong>FFF Enterprises, Inc.<br/></strong>44000 Winchester Road<br/>Temecula, CA 92590<br/><abbr title="Phone">Phone:</abbr> 800.843.7477,&nbsp;951.296.2500<br/><abbr title="Fax">Fax:</abbr>&nbsp;800.418.4333</p>
                  <p ><a title="NABP® Accredited Drug Distributor" href="https://nabp.pharmacy/programs/accreditations-inspections/drug-distributor/accredited-drug-distributors/" className={classes.footerLink} style={{textAlign:'left'}} target="_blank" rel="noopener">FFF Enterprises is a NABP&reg; Accredited Drug Distributor</a><br/>
                  <a href="https://www.fffenterprises.com/" className={classes.footerLink} style={{textAlign:'left'}} target="_blank" rel="noopener">ODRescue&trade; is a registered trademark of FFF Enterprises, Inc.</a></p>
              <p className={`${classes.footerCaptcha}`}>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
            </Grid>
            <Grid item xs={0} md={2} sx={{display: 'flex', justifyContent: 'center'}}>
              <Divider orientation="vertical" flexItem variant={'middle'} sx={{ bgcolor: '#eee' }}/>
            </Grid>
            <Grid item xs={12} md={2} className={isDesktopOrLaptop ? classes.footerLinks : classes.footerLinksMobile}>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/product-details`}>Product Details</Button>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/resources`}>Resources</Button>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/about`}>About Us</Button>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/contact`}>Contact Us</Button>
              <Button className={`${classes.footerLink}`} onClick={() => routeChange('/')}>Order</Button>
              <Button className={`${classes.footerLink}`} onClick={() => routeChange('/order')}>Track Order</Button>
            </Grid>
            <Grid item xs={12} md={3} className={isDesktopOrLaptop ? classes.footerLinks : classes.footerLinksMobile}>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/privacy-statement`}>Privacy Statement</Button>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/terms-of-use`}>Terms of Use</Button>
              <Button className={`${classes.footerLink}`} onClick={() => window.location.href = `${config.wordpressUrl}/terms-of-sale`}>Terms and Conditions of Sale</Button>
              <Button id="ot-sdk-btn" className={`${classes.footerLink} ot-sdk-show-settings`} sx={{textAlign: 'left !important'}}>Do Not Sell My Personal Information</Button>
            </Grid>
            <Grid item xs={12} md={12} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '40px'}}>
              <FacebookIcon fontSize='large' className={`${classes.footerSocialLink}`} onClick={() => window.location.href = `https://www.facebook.com/profile.php?id=61559253924557`}></FacebookIcon>
              <XIcon fontSize='large' className={`${classes.footerSocialLink}`} onClick={() => window.location.href = `https://twitter.com/ODRescue`}></XIcon>
              <LinkedInIcon fontSize='large' className={`${classes.footerSocialLink}`} onClick={() => window.location.href = `https://www.linkedin.com/in/odrescue%E2%84%A2-by-fff-enterprises-inc-60954730b/`}></LinkedInIcon>
            </Grid>
            <Grid item xs={12} md={12} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <p className={`${classes.footerLegalFFF}`}>&copy;2024 ODRescue&trade;, a subsidiary of FFF Enterprises, Inc. All Rights Reserved. Property of ODRescue&trade;.</p>
            </Grid>
          </Grid>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={config.captchaSiteKeyInvisible}
            badge='inline'
            onErrored={()=>console.log('onErrored called')}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isLoading: state.ui.isLoading,
  selectedTheme: 'light', //state.user.selectedTheme
  dataIsPreloaded: state.ui.dataIsPreloaded
});

const mapDispatchToProps = (dispatch: any) => ({
  setUrlFilter: (queryString: string) => { },
  preloadData: () => dispatch(UIActions.preLoadData({})),
})

export default connect(mapStateToProps, mapDispatchToProps)(BasePage);

