import * as React from 'react';
import { BasePageStyles, globalColors } from '../../hooks/styles';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, Checkbox, FormControl, FormControlLabel, Grid, Input, InputAdornment, InputLabel, TextField, Tooltip } from '@mui/material';
import { IMAGES_URL } from '../../constants/Images';
import NumberInput from '../../components/numberPicker';
import { EvaluateExpression } from '../../hooks/functions/EvaluateExpression';
import config from '../../config';

interface ProductProps {
	odrQuantity: number
	narcanQuantity: number
	narcanChecked: boolean
	autoCalcNarcanChecked: boolean
	products: any[]
	setOdrQuantity: (quantity: number) => void
	setNarcanQuantity: (quantity: number) => void
	setNarcanChecked: (isChecked: boolean) => void
	setAutoCalcNarcanChecked: (isChecked: boolean) => void
}

const productDescription = <div>
	<h2><strong>Description</strong></h2>

	<p>ODRescue&trade; Box is strategically designed to help make life-saving Naloxone readily available and is ideal for high-visibility areas to ensure swift action when needed. It was developed with first responder and nursing input to support rapid response. <b>Note: NARCAN&reg; Nasal Spray sold separately.</b></p>

	<h3>Key Features:</h3>

	<ul>
		<li>Clear box with handle for transportation</li>
		<li>Wall mount and hardware to make box visible and accessible</li>
		<li>Front identifies 5 common overdose signs</li>
		<li>Safety lock – to detect use or tampering</li>
		<li>Back includes QR codes to training video, report use, and reorder components</li>
	</ul>

	<h3>Included in each ODRescue&trade; Box</h3>

	<ul>
		<li>Clear plexiglass box with handle</li>
		<li>Wall mount and mounting hardware</li>
		<li>CPR kit (adult/child CPR mask, one-way valve with filter, nitrile gloves, antiseptic wipe)</li>
		<li>Customizable front for orders greater than 100 units</li>
		<li><b>Note: NARCAN&reg; Nasal Spray sold separately</b></li>
	</ul>
</div>

export const ProductComponent: React.FC<ProductProps> = (props) => {
	const classes = BasePageStyles();
	const history = useHistory(); 
	const NARCAN_PRODUCT_ID = 'NAR062702'
	const NARCAN_PRICE = parseFloat(props.products.find((product: any) => product.id === NARCAN_PRODUCT_ID)?.price || 0);
	const ODR_KIT = config.odrKitProductId
	const ODR_KIT_PRICE = parseFloat(props.products.find((product: any) => product.id === ODR_KIT)?.price || 0);
	const MIN_QTY = 1
	const MAX_QTY = 1000
	const [odrQuantity, setOdrQuantity] = React.useState(props.odrQuantity || MIN_QTY);
	const [narcanQuantity, setNarcanQuantity] = React.useState(props.narcanQuantity || 0);
	const [tooltipTitle, setTooltipTitle] = React.useState(`Min ${MIN_QTY}`);
	const [narcanTooltipTitle, setNarcanTooltipTitle] = React.useState(`Min ${MIN_QTY}`);
	const isFirstRender = React.useRef(true);

	const routeChange = (path: string) =>{ 
		history.push(path);
	}

	const onBuyNow = () => {
		//Add to Cart
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			"event": "add_to_cart",
			"ecommerce": {
				"items": [{
					"index": 0,
					"price": ODR_KIT_PRICE,
					"coupon": "",
					"item_id": ODR_KIT,
					"discount": 0,
					"quantity": odrQuantity,
					"item_name": "ODRescue Box",
					"item_brand": "ODRescue",
					"affiliation": "FFF Enterprises",
					"item_variant": "",
					"item_category": ""
				},
				{
					"index": 0,
					"price": NARCAN_PRICE,
					"coupon": "",
					"item_id": NARCAN_PRODUCT_ID,
					"discount": 0,
					"quantity": narcanQuantity,
					"item_name": "NARCAN Nasal Spray 4 mg",
					"item_brand": "Narcan",
					"affiliation": "Narcan Shop",
					"item_variant": "",
					"item_category": ""
				}
			],
				"value": EvaluateExpression(`(${odrQuantity} * ${ODR_KIT_PRICE}) + (${narcanQuantity} * ${NARCAN_PRICE})`),
				"currency": "USD"
			}
		});
		props.setOdrQuantity(odrQuantity)
		props.setNarcanQuantity(narcanQuantity)
		routeChange('/payment')
	}

	const isTooltipOpen = () => {
		return !isFirstRender.current && (odrQuantity === MIN_QTY || odrQuantity === MAX_QTY)
	}

	const isNarcanTooltipOpen = () => {
		return !isFirstRender.current && (narcanQuantity === MIN_QTY || narcanQuantity === MAX_QTY * 2)
	}

	const onOdrQuantityChange = (newQty: number) => {
		if(newQty < MIN_QTY) newQty = MIN_QTY
		if(newQty > MAX_QTY) newQty = MAX_QTY
		setOdrQuantity(newQty)
		if (newQty === MIN_QTY) setTooltipTitle(`Min ${MIN_QTY}`)
		else if (newQty === MAX_QTY) setTooltipTitle(`Max ${MAX_QTY}, contact customer support to place larger orders.`)
		if(isFirstRender.current) isFirstRender.current = false;
		if(props.narcanChecked && props.autoCalcNarcanChecked) onNarcanQuantityChange(newQty * 2)
	}

	const onNarcanQuantityChange = (newQty: number) => {
		if(newQty < MIN_QTY) newQty = MIN_QTY
		if(newQty > MAX_QTY * 2) newQty = MAX_QTY * 2
		setNarcanQuantity(newQty)
		if (newQty === MIN_QTY) setNarcanTooltipTitle(`Min ${MIN_QTY}`)
		else if (newQty === MAX_QTY * 2) setNarcanTooltipTitle(`Max ${MAX_QTY * 2}`)
		if(isFirstRender.current) isFirstRender.current = false;
	}

	const onNarcanClicked = (isChecked: boolean) => {
		setNarcanQuantity(isChecked ? odrQuantity * 2 : 0)
		props.setNarcanChecked(isChecked)
		props.setAutoCalcNarcanChecked(true)
	}

	const onAutoCalcNarcanClicked = (isChecked: boolean) => {
		if(isChecked) onNarcanQuantityChange(odrQuantity * 2)
		props.setAutoCalcNarcanChecked(isChecked)
	}

	React.useEffect(() => {
		//View Item
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			"event": "view_item",
			"ecommerce": {
				"items": [{
					"index": 0,
					"price": ODR_KIT_PRICE,
					"coupon": "",
					"item_id": ODR_KIT,
					"discount": 0,
					"quantity": odrQuantity,
					"item_name": "ODRescue Box",
					"item_brand": "ODRescue",
					"affiliation": "FFF Enterprises",
					"item_variant": "",
					"item_category": ""
				},
				{
					"index": 0,
					"price": NARCAN_PRICE,
					"coupon": "",
					"item_id": NARCAN_PRODUCT_ID,
					"discount": 0,
					"quantity": narcanQuantity,
					"item_name": "NARCAN Nasal Spray 4 mg",
					"item_brand": "Narcan",
					"affiliation": "Narcan Shop",
					"item_variant": "",
					"item_category": ""
				}
			],
				"value": EvaluateExpression(`(${odrQuantity} * ${ODR_KIT_PRICE}) + (${narcanQuantity} * ${NARCAN_PRICE})`),
				"currency": "USD"
			}
		});
	}, []);

	return (
		<Grid container item xs={12} md={8}>
			<Grid item xs={12}>
				<h1>Order ODRescue&trade; Box</h1>
			</Grid>
			<Grid container item xs={12} md={6}>
				<Box sx={{paddingLeft: '50px'}}>
				<Grid item xs={12} sx={{display: 'flex'}}>
					<FormControlLabel
						label={(<span>Add NARCAN&reg; Nasal Spray 4mg</span>)}
						className={classes.termsCheck}
						control={
							<Checkbox
								checked={props.narcanChecked}
								onChange={(event, isChecked) => {onNarcanClicked(isChecked)}}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
					/>
				</Grid>
				<Grid container item xs={12} md={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
					<Grid >
						<Tooltip title={tooltipTitle} open={isTooltipOpen()} PopperProps={{ disablePortal: true }} arrow>
							<NumberInput aria-label="Quantity Input" min={MIN_QTY} max={MAX_QTY} 
								title={'ODRescue\u2122 Box'}
								value={odrQuantity}
								onChange={(event, newValue) => {onOdrQuantityChange(newValue || 0)}}
								onInputChange={(event) => {onOdrQuantityChange(parseInt(event.target.value || '0'))}}
								endAdornment={<InputAdornment position="end" sx={{marginLeft: '-35px', marginRight: '18px'}}>bx</InputAdornment>}
							/>
						</Tooltip>
					</Grid>
					{props.narcanChecked && <Grid sx={{maxWidth: '50%'}}>
						<Tooltip title={narcanTooltipTitle} open={isNarcanTooltipOpen()} PopperProps={{ disablePortal: true }} arrow>
							<NumberInput aria-label="Quantity Input" min={MIN_QTY} max={MAX_QTY*2} 
								title={'Narcan\u00AE Nasal Spray 4 mg'}
								value={narcanQuantity}
								onChange={(event, newValue) => {onNarcanQuantityChange(newValue || 0)}}
								onInputChange={(event) => {onNarcanQuantityChange(parseInt(event.target.value || '0'))}}
								endAdornment={<InputAdornment position="end" sx={{marginLeft: '-35px', marginRight: '18px'}}>bx</InputAdornment>}
								disabled={props.autoCalcNarcanChecked}
							/>
						</Tooltip>
						<FormControlLabel
							label={(<span>Two boxes of NARCAN&reg; (4 doses) per ODRescue&trade; Box</span>)}
							className={classes.termsCheck}
							sx={{display: 'flex', justifyContent: 'center', marginTop: '18px'}}
							control={
								<Checkbox
									checked={props.autoCalcNarcanChecked}
									onChange={(event, isChecked) => {onAutoCalcNarcanClicked(isChecked)}}
									inputProps={{ 'aria-label': 'controlled' }}
									size={'small'}
								/>
							}
						/>
					</Grid>}
				</Grid>
					<Grid  item xs={12} sx={{ marginTop: '20px'}}>
						<Button variant="contained" size='large'
							onClick={onBuyNow}
						>Buy Now</Button>
					</Grid>
				</Box>
				<Grid item xs={12} md={12}>
					{productDescription}
				</Grid>
			</Grid>

			<Grid item xs={12} md={6}>
				<img src={IMAGES_URL.ODR_PRODUCT} style={{maxWidth: '100%'}}></img>
			</Grid>
			
			<Grid item xs={12} sx={{textAlign: 'center', paddingTop: '50px'}}>
				<p>NARCAN&reg; is a registered trademark of Emergent Operations Ireland Limited.</p>
			</Grid>
		</Grid>
	)
}